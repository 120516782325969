/* eslint-disable react/jsx-no-bind, no-return-assign, react/no-array-index-key */

import EventsAPI from "../../../shared/modules/EventsAPI";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import SharedPropTypes from "../../Swap/modules/SharedPropTypes";
import TinySliderOverride from "./TinySliderOverride";
import {withCookies} from "react-cookie";

class ProductPanelImageOrCarousel extends React.PureComponent {

  static propTypes = {
    "cookies": PropTypes.object.isRequired,
    "handleShowSwapModal": PropTypes.func,
    "isGrid": PropTypes.bool.isRequired,
    "isMobile": PropTypes.bool.isRequired,
    "isSwapped": PropTypes.func.isRequired,
    "previewSwapping": PropTypes.bool,
    "productDescriptor": PropTypes.object.isRequired,
    "productDescriptors": SharedPropTypes.productDescriptors,
    "queryParamBuilder": PropTypes.func.isRequired,
    "showClearancePrice": PropTypes.bool.isRequired,
    "showModal": PropTypes.func.isRequired
  };

  static defaultProps = {
    "handleShowSwapModal": null,
    "isLoggedOutHomepage": false,
    "previewSwapping": false,
    "productDescriptors": null
  };

  constructor (props) {
    super(props);
    const {isGrid, previewSwapping} = props;
    this.state = {
      "currentImageIndexShown": 0,
      "omitQuickView": previewSwapping || !isGrid || window.experimentVariants.includes("add_to_cart_from_grid_var_b")
    };
  }

  handleSwapModal = (event) => {
    const {isMobile, previewSwapping, showModal} = this.props;

    if (previewSwapping && isMobile) {
      showModal(event);
    }
  };

  sliderMovesTo = (event, index) => {
    event.preventDefault();
    this.ts.slider.goTo(index);
  }

  updateCurrentIndex = () => {
    const sliderInfo = this.ts.slider.getInfo();
    const indexCurrent = sliderInfo.displayIndex - 1;

    this.setState({"currentImageIndexShown": indexCurrent});
  }

  renderSingleImageTag = (sourceUrl, imageClassName, key) => {
    const {productDescriptor} = this.props;
    const {"details": {designer, name}} = productDescriptor;

    return (
      <img
        alt={`${designer} ${name}`}
        className={imageClassName}
        key={key}
        src={sourceUrl}
      />
    );
  }

  renderImageTags = (withMobileQuickview) => {
    const {omitQuickView} = this.state;
    const {
      isGrid,
      isMobile,
      productDescriptor,
      showModal
    } = this.props;
    const {"details": {"thumbnail_urls": thumbnailImages}} = productDescriptor;
    const images = (
      <div>
        {thumbnailImages.slice(0, 2).map((el, index) => {
          const imageClass = index === 0 ? "product-photo" : "ugc-photo";
          if (isMobile && !isGrid && index !== 0) return null;
          return this.renderSingleImageTag(el, `pd_image grid-item-link ${imageClass}`, `thumbnail_${index}`);
        })}
      </div>
    );

    return (
      <React.Fragment>
        {images}
        {withMobileQuickview && !omitQuickView &&
          <div
            className="quick-view-mobile"
            onClick={showModal}
          />}
      </React.Fragment>
    );
  }

  renderTinySlider = () => {
    const {currentImageIndexShown, omitQuickView} = this.state;
    const {
      productDescriptor,
      showModal
    } = this.props;
    const {"details": {"thumbnail_urls": thumbnailImages}} = productDescriptor;

    const tinySliderSettings = {
      "controls": false,
      "items": 1,
      "lazyload": true,
      "mouseDrag": true,
      "nav": false,
      "startIndex": 0
    };

    return (
      <div>
        <div>
          <TinySliderOverride
            onIndexChanged={this.updateCurrentIndex}
            ref={ts => this.ts = ts}
            settings={tinySliderSettings}
          >
            {thumbnailImages.slice(0, 3).map((el, index) =>
              this.renderSingleImageTag(el, "product-photo grid-item-link", `slider_image_${index}`))}
          </TinySliderOverride>
          <div className="slider-bullets">
            {thumbnailImages.slice(0, 3).map((el, index) => (
              <span
                className={`slider-bullet${currentImageIndexShown === index ? " active" : ""}`}
                key={index}
                onClick={event => this.sliderMovesTo(event, index)}
              />
            ))}
          </div>
        </div>
        {!omitQuickView &&
          <div
            className="quick-view-mobile"
            onClick={showModal}
          />}
      </div>
    );
  }

  renderImageOrCarousel = () => {
    const {
      isGrid,
      isMobile,
      productDescriptor
    } = this.props;
    const {"details": {"thumbnail_urls": thumbnailImages}} = productDescriptor;

    if (!isMobile || !isGrid) {
      return this.renderImageTags(false);
    } else if (thumbnailImages.length > 1) {
      return this.renderTinySlider();
    }

    return this.renderImageTags(true);
  }

  sendContactEvent = (event, eventName = null) => {
    const {cookies, productDescriptor} = this.props;
    EventsAPI.trackContactEvent(
      `clicked_on_product_panel_${eventName}`,
      `product descriptor: ${productDescriptor.id}`,
      cookies.get("anonymous_contact_id")
    );
  };

  render = () => {
    const {
      isGrid,
      isSwapped,
      productDescriptor,
      queryParamBuilder,
      showClearancePrice,
      previewSwapping
    } = this.props;
    const {"details": {name, url}} = productDescriptor;
    const search = queryParamBuilder(
      window.location.search,
      showClearancePrice ? "from_clearance=true" : ""
    );
    if (previewSwapping) {
      return (
        <div onClick={isSwapped() ? null : this.handleSwapModal}>
          {this.renderImageOrCarousel()}
        </div>
      );
    }

    if (!isGrid) {
      return (
        <div>
          <a
            href={url + search}
            onClick={event => this.sendContactEvent(event, "from_homepage")}
            title={name}
          >
            {this.renderImageOrCarousel()}
          </a>
        </div>
      );
    }

    if (window.experimentVariants.includes("pdp_new_tab_var_a")) {
      return (
        <div>
          <a
            href={url + search}
            target="_blank"
            title={name}
          >
            {this.renderImageOrCarousel()}
          </a>
        </div>
      );
    }

    return (
      <div>
        <Link
          onClick={this.sendContactEvent}
          title={name}
          to={{
            "pathname": url,
            search,
            "state": {"prevPath": window.location.pathname}
          }}
        >
          {this.renderImageOrCarousel()}
        </Link>
      </div>
    );
  }
}

export default withCookies(ProductPanelImageOrCarousel);
