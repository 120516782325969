/* eslint-disable arrow-parens, max-len, max-params */
import Helper from "./Helper";

export default class AnalyticsHelper {
  static setSessionStorage = (eventDetail) => {
    sessionStorage.setItem("segmentEventTriggered", eventDetail);
  };

  static sendFooterSignupEvent = (email) => {
    const eventName = "Sign Up from Footer";
    const eventDetail = `${eventName} for: ${email}`;
    window.analytics.track(eventName, {
      email,
      "signupLocation": window.location.pathname
    },
    this.setSessionStorage(eventDetail));
  };

  static sendPurchasedCartEvent = (invoice = {}, orders = {}) => {
    const invoiceNumber = JSON.parse(invoice).invoice_number;
    const eventName = "Purchased Cart";
    const eventDetail = `${eventName} for invoice: ${invoiceNumber}`;
    window.analytics.track(eventName, {
      invoice,
      orders
    },
    this.setSessionStorage(eventDetail));
  };

  static sendPurchasedCartEventForStripe = (orderDetail) => {
    const total = Helper.formatCentsToPrice(orderDetail.total);
    const eventName = "Purchased Cart";
    const eventDetail = `$${total} for anonymous_contact_id: ${orderDetail.anonymous_contact_id}`;
    window.analytics.track(eventName, {
      "anonymous_contact_id": orderDetail.anonymous_contact_id,
      "email": orderDetail.email,
      total
    },
    this.setSessionStorage(`${eventName}: ${eventDetail}`));
  };

  static sendBecameMemberFrontendAndSubscribeEvent = (customerId, analyticsProps) => {
    if (!(localStorage.getItem("becameMemberEvent") &&
        localStorage.getItem("becameMemberEvent") === customerId.toString())) {
      // analytics tracking
      window.analytics.page("Signup Confirmation"); // eslint-disable-line no-undef
      setTimeout(function () { // eslint-disable-line prefer-arrow-callback, func-names
        window.analytics.identify(`${customerId}`, // eslint-disable-line no-undef
          {
            firstName: `${analyticsProps.firstName}`, // eslint-disable-line quote-props
            lastName: `${analyticsProps.lastName}`, // eslint-disable-line quote-props
            phoneNumber: `${analyticsProps.phoneNumber}`, // eslint-disable-line quote-props
            status: `${analyticsProps.status}`, // eslint-disable-line quote-props
            bff_code: `${analyticsProps.bffCode}`, // eslint-disable-line quote-props, camelcase, sort-keys
            subscriptionGeneration: `${analyticsProps.subscriptionGeneration}` // eslint-disable-line quote-props
          },
          {ip: `${analyticsProps.ip}`}); // eslint-disable-line quote-props
        // we wait half a second here b/c track needs to be called after identify
        // for event to be correctly associated to customer in iterable
        setTimeout(function () { // eslint-disable-line prefer-arrow-callback, func-names
          window.analytics.track("Became member - frontend", analyticsProps.attributes);
          // "Subscribe" is specifically for Facebook
          window.analytics.track("Subscribe", analyticsProps.attributes); // eslint-disable-line no-undef
        }, 500);
      }, 500);
      localStorage.setItem("becameMemberEvent", customerId);
    }
  };

  static sendProductViewedEvent = (productDescriptor) => {
    window.analytics.track("Viewed Product", this.getProductDescriptorProps(productDescriptor));
  };

  static sendProductAddedToCartEvent = (productDescriptor) => {
    window.analytics.track("Item Added to Cart", this.getProductDescriptorProps(productDescriptor));
  }

  static sendPageViewedEvent = (eventName) => {
    console.log(`FJR: ${eventName}`);
    window.analytics.track(`FJR: ${eventName}`);
  }

  /* eslint-disable no-undef */
  static getProductDescriptorProps = (productDescriptor) => {
    const {name, sku, designer, url} = productDescriptor.details;
    const {
      "kind_name": kindName,
      "retail_price": retailPrice,
      "full_size_urls": fullSizeUrls
    } = productDescriptor.details;
    return {
      "campaign_id": getCookie("campaign_id"),
      "product_designer": designer,
      "product_image": fullSizeUrls[0],
      "product_kind": kindName,
      "product_link": url,
      "product_name": name,
      "product_price": Helper.formatDollarsForIterable(retailPrice),
      "product_sku": sku,
      "template_id": getCookie("template_id")
    };
  }
  /* eslint-enable no-undef */
}
